import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

import { useForm } from "react-hook-form";

const geoUrl = "/imgs/world-countries.json";

const markerColor = (category) => {
  const colors = { 0: "#FFA4EE", 1: "#C5FFCE", 2: "#FF6600" };
  return colors[category];
};

const countries = ["Spain", "Poland", "Morocco", "France", "Turkey"];

const contryColor = (contryName) => {
  if (countries.includes(contryName)) {
    return "#B7DBFF";
  } else {
    return "#DFFFEC";
  }
};

const IMAGES = {
  "-5_6-36_0": {
    category: 1,
    markerOffset: 0,
    name: "Tarifa",
    coordinates: [-5.6, 36.0],
    images: [
      {
        title: "Like one wasn't enough",
        url: "./PANO_20220529_145310.jpg",
      },
      {
        title: "A real angel",
        url: "./IMG_20220529_130552.jpg",
      },
      {
        title: "Love, love, love",
        url: "./IMG_20220529_145047_1.jpg",
      },
      {
        title: "Skyline",
        url: "./IMG_20220529_175905.jpg",
      },
      {
        title: "Pegi 3",
        url: "./IMG_20220529_145058.jpg",
      },
      {
        title: "Moar",
        url: "./IMG_20220529_175908.jpg",
      },
      {
        title: "OOOOhhhhhh!",
        url: "./IMG_20220528_190437.jpg",
      },
      {
        title: "Spanish hike",
        url: "./IMG_20220528_192510.jpg",
      },
    ],
  },
  "None-None": {
    category: 1,
    markerOffset: 0,
    name: "Nowhere",
    coordinates: [0, 0],
    images: [
      {
        title: "My Big Girl",
        url: "./IMG-20221116-WA0015.jpg",
      },
      {
        title: "MMA training",
        url: "./IMG-20220614-WA0026.jpg",
      },
      {
        title: "Kids having fun",
        url: "./IMG-20220807-WA0027.jpg",
      },
      {
        title: "Happy as a duck",
        url: "./IMG-20220821-WA0006.jpg",
      },
      {
        title: "The gang",
        url: "./IMG-20220618-WA0006.jpg",
      },
      {
        title: "Yabadabadoooooooooo",
        url: "./IMG-20221030-WA0000.jpg",
      },
      {
        title: "Oh Canada!",
        url: "./IMG-20220714-WA0040.jpg",
      },
      {
        title: "Hello Win!",
        url: "./IMG-20221031-WA0012.jpg",
      },
      {
        title: "Big Boy",
        url: "./IMG-20221012-WA0010.jpg",
      },
      {
        title: "I got bitches",
        url: "./IMG-20221207-WA0000.jpg",
      },
      {
        title: "On an adventure",
        url: "./IMG-20220719-WA0014.jpg",
      },
    ],
  },
  "20_9-49_6": {
    category: 1,
    markerOffset: 0,
    name: "Polna",
    coordinates: [20.9, 49.6],
    images: [
      {
        title: "They said purple",
        url: "./IMG_20221224_160136.jpg",
      },
      {
        title: "We found big foot",
        url: "./IMG_20221224_121418.jpg",
      },
      {
        title: "It's green",
        url: "./IMG_20221010_133742.jpg",
      },
      {
        title: "Love <3",
        url: "./IMG_20221009_150551.jpg",
      },
      {
        title: "Who is that?",
        url: "./IMG_20221009_135306.jpg",
      },
      {
        title: "Frozen and the kids",
        url: "./IMG_20221224_121511.jpg",
      },
      {
        title: "Merry Xmas",
        url: "./IMG_20221225_094854.jpg",
      },
      {
        title: "I don't want to see",
        url: "./IMG_20221009_111945.jpg",
      },
      {
        title: "They see me rolling!",
        url: "./IMG_20221010_132607.jpg",
      },
      {
        title: "Let it go?",
        url: "./IMG_20221224_121016.jpg",
      },
      {
        title: "Snow man and the gang",
        url: "./IMG_20221224_121433.jpg",
      },
    ],
  },
  "19_8-49_3": {
    category: 1,
    markerOffset: 0,
    name: "Zakopane",
    coordinates: [19.8, 49.3],
    images: [
      {
        title: "Happy new year 2023 ;)",
        url: "./IMG_20230101_013810.jpg",
      },
      {
        title: "Let's roll!",
        url: "./IMG_20221230_150942.jpg",
      },
      {
        title: "Zak!",
        url: "./IMG_20221231_142056.jpg",
      },
    ],
  },
  "29_1-37_9": {
    category: 1,
    markerOffset: 0,
    name: "Pamukkale",
    coordinates: [29.1, 37.9],
    images: [
      {
        title: "Instal model",
        url: "./IMG_20230701_085606.jpg",
      },
      {
        title: "",
        url: "./IMG_20230701_085406.jpg",
      },
      {
        title: "Pamu gardens",
        url: "./IMG_20230630_200646.jpg",
      },
      {
        title: "Hey Sexy",
        url: "./IMG_20230701_093356.jpg",
      },
      {
        title: "Where is Cleopatre?",
        url: "IMG_20230701_122035.jpg",
      },
    ],
  },
  "31_2-37_1": {
    category: 1,
    markerOffset: 0,
    name: "Antalya",
    coordinates: [31.2, 37.1],
    images: [
      {
        title: "",
        url: "./IMG_20230703_165855.jpg",
      },
      {
        title: "Going to chill",
        url: "./IMG_20230703_114613.jpg",
      },
      { titl: "[HY]ikes", url: "IMG_20230704_145716.jpg" },
      {
        title: "",
        url: "./IMG_20230704_230120.jpg",
      },
      {
        title: "Almost not veggie",
        url: "./IMG_20230704_220309.jpg",
      },
      {
        title: "Pirate!",
        url: "./IMG_20230702_122102.jpg",
      },
      {
        title: "Lovely BD",
        url: "./IMG_20230704_104010.jpg",
      },
      {
        title: "I am blue, dabedi, dabedou",
        url: "IMG_20230704_115204.jpg",
      },
    ],
  },
  "19_9-50_0": {
    category: 1,
    markerOffset: 0,
    name: "Krakow",
    coordinates: [19.9, 50.0],
    images: [
      {
        title: "Call a dragon slayer",
        url: "./IMG_20221008_114737.jpg",
      },
      {
        title: "FOOOOOOD",
        url: "./IMG_20221012_144442.jpg",
      },
      {
        title: "Sunny Krak",
        url: "./IMG_20221012_120713.jpg",
      },
      {
        title: "Polish Jungle",
        url: "./IMG_20221012_125615.jpg",
      },
      {
        title: "Fall... in love?",
        url: "./IMG_20221012_133001.jpg",
      },
      {
        title: "Italy wasn't that comfy",
        url: "./IMG_20221008_154438.jpg",
      },
    ],
  },
  "-5_8-35_7": {
    category: 1,
    markerOffset: 0,
    name: "Tangier",
    coordinates: [-5.8, 35.7],
    images: [
      {
        title: "Safari in the jungle",
        url: "./IMG_20230820_180920.jpg",
      },
    ],
  },
  "-6_8-33_9": {
    category: 1,
    markerOffset: 0,
    name: "Rabat",
    coordinates: [-6.8, 33.9],
    images: [
      {
        title: "Happy birthday ;)",
        url: "./IMG_20220614_205810.jpg",
      },
      {
        title: "Hungry woman",
        url: "./IMG_20220614_193931.jpg",
      },
    ],
  },
  "28_9-41_0": {
    category: 1,
    markerOffset: 0,
    name: "Istanbul",
    coordinates: [28.9, 41.0],
    images: [
      {
        title: "",
        url: "./ISTANBUL_IMG_20230620_231504.jpg",
      },
      {
        title: "Hajja Carly",
        url: "./IMG_20230621_103318.jpg",
      },
      {
        title: "Fishy!",
        url: "./IMG_20230626_184111.jpg",
      },
      {
        title: "A new beginning",
        url: "./ISTANBUL_IMG_20230621_102433.jpg",
      },
      {
        title: "Brightness",
        url: "./ISTANBUL_IMG_20230620_231445.jpg",
      },
    ],
  },
  "-4_9-34_0": {
    category: 1,
    markerOffset: 0,
    name: "Fes",
    coordinates: [-4.9, 34.0],
    images: [
      {
        title: "Birdy",
        url: "./FES_IMG_20230402_100100.jpg",
      },
      {
        title: "Stalker",
        url: "./FES_IMG_20230401_201402.jpg",
      },
      {
        title: "Beautiful fess",
        url: "./FES_IMG_20230402_100129.jpg",
      },
      {
        title: "Good buy fes",
        url: "./FES_IMG_20230402_110130.jpg",
      },
    ],
  },
  "-6_9-33_9": {
    category: 1,
    markerOffset: 0,
    name: "Harourra",
    coordinates: [-6.9, 33.9],
    images: [
      {
        title: "Family forest hike",
        url: "./IMG_20220611_105259.jpg",
      },
      {
        title: "GNOCHHHHIIII!!!",
        url: "./IMG_20220417_124812.jpg",
      },
      {
        title: "Home sweet home",
        url: "./IMG_20220817_200328.jpg",
      },
      {
        title: "Crazy dog at sunset",
        url: "./IMG_20220926_192532.jpg",
      },
      {
        title: "Kooga Contemplating at Twilight's Embrace",
        url: "./IMG_20220926_191036.jpg",
      },
      {
        title: "Climbing Tangier",
        url: "./IMG_20220619_121926.jpg",
      },
      {
        title: "Moroccan gangsta",
        url: "./IMG_20220927_122255.jpg",
      },
    ],
  },
  "-6_0-35_4": {
    category: 1,
    markerOffset: 0,
    name: "Asilah",
    coordinates: [-6.0, 35.4],
    images: [
      {
        title: "Matchy matchy",
        url: "./ASILAH_IMG_20230219_115715.jpg",
      },
      {
        title: ":D",
        url: "./ASILAH_IMG_20230219_163313.jpg",
      },
      {
        title: "Assis là!",
        url: "./IMG_20230219_115020.jpg",
      },
      {
        title: "Some charater!",
        url: "./ASILAH_IMG_20230218_151852.jpg",
      },
    ],
  },
  "-6_7-34_1": {
    category: 1,
    markerOffset: 0,
    name: "PDN",
    coordinates: [-6.7, 34.1],
    images: [
      {
        title: "burying evidence",
        url: "./IMG_20231124_100432.jpg",
      },
      {
        title: "In love people",
        url: "./IMG_20231126_104618.jpg",
      },
      {
        title: "Just cool people",
        url: "./IMG_20231126_104605.jpg",
      },
    ],
  },
  "27_1-38_4": {
    category: 1,
    markerOffset: 0,
    name: "Izmir",
    coordinates: [27.1, 38.4],
    images: [
      {
        title: "Something about women",
        url: "./IMG_20230627_212610.jpg",
      },
      {
        title: "Crushed!",
        url: "./IMG_20230628_142855.jpg",
      },
      {
        title: "Everynight in my dream",
        url: "./IMG_20230628_171938.jpg",
      },
    ],
  },
  "-7_6-31_2": {
    category: 1,
    markerOffset: 0,
    name: "Ourika",
    coordinates: [-7.6, 31.2],
    images: [
      {
        title: "Exploration team",
        url: "./OURIKA_IMG_20230528_121545.jpg",
      },
      {
        title: "Melting pot",
        url: "./OURIKA_IMG_20230527_195050.jpg",
      },
    ],
  },
  "-6_6-33_6": {
    category: 1,
    markerOffset: 0,
    name: "Ferme rouge",
    coordinates: [-6.6, 33.6],
    images: [
      {
        title: "It was before the wine tasting",
        url: "./IMG_20220924_160344.jpg",
      },
    ],
  },
  "2_3-48_8": {
    category: 1,
    markerOffset: 0,
    name: "Paris",
    coordinates: [2.3, 48.8],
    images: [
      {
        title: "City of love",
        url: "./PARIS_IMG_20231024_161609.jpg",
      },
      {
        title: "Tallest lady",
        url: "./PARIS_IMG_20231025_141537.jpg",
      },
      {
        title: "Paris is dangerous!",
        url: "./PARIS_IMG_20231025_183520.jpg",
      },
    ],
  },
  "-8_4-33_2": {
    category: 1,
    markerOffset: 0,
    name: "El Jadida",
    coordinates: [-8.4, 33.2],
    images: [
      {
        title: "What the fog",
        url: "./IMG_20230929_173717.jpg",
      },
      {
        title: "Best breakfast ever",
        url: "./IMG_20230930_085223.jpg",
      },
    ],
  },
  "-6_6-34_2": {
    category: 1,
    markerOffset: 0,
    name: "Kenitra",
    coordinates: [-6.6, 34.2],
    images: [
      {
        title: "\\_°<",
        url: "./IMG_20220514_170919.jpg",
      },
    ],
  },
  "27_3-37_9": {
    category: 1,
    markerOffset: 0,
    name: "Denizli",
    coordinates: [27.3, 37.9],
    images: [
      {
        title: "Home",
        url: "././IMG_20230629_152227.jpg",
      },
      {
        title: "Theater",
        url: "./IMG_20230629_153057.jpg",
      },
    ],
  },
  "-6_6-33_9": {
    category: 1,
    markerOffset: 0,
    name: "Lac Something",
    coordinates: [-6.6, 33.9],
    images: [
      {
        title: "Before the drawn",
        url: "./IMG_20230415_122547.jpg",
      },
      {
        title: "Trax's angels",
        url: "./IMG_20230415_162037.jpg",
      },
    ],
  },
};

const Main = () => {
  const [images, setImages] = useState();
  const [modalTitleIndex, setModalTitleIndex] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [zoomFactor, setZoomFactor] = useState(0.1);
  const target = useRef(null);
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const addPicture = (data) => {
    console.log(JSON.stringify(data));
  };

  const menu = () => {
    return (
      <Modal
        show={showMenu}
        dialogClassName="modal-90w"
        onHide={() => {
          setShowMenu(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(addPicture)}>
            <Form.Group className="mb-3" controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Paris"
                {...register("location", { required: true })}
              />
              <Form.Text className="text-muted">Name or coordinates</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="linky">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://"
                {...register("link", { required: true })}
              />
              <Form.Text className="text-muted">
                Upgrade to paid version to store your pictures directly
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Select
                id="category"
                {...register("category", { required: true })}
              >
                <option>Vacation</option>
                <option>Work</option>
                <option>Red</option>
                <option>Pink</option>
                <option>Brown</option>
              </Form.Select>
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="My Awesome Souvenir!" />
              <Form.Text className="text-muted">Optional</Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    setModalTitleIndex(0);
  }, [show]);

  const modal = (images) => {
    console.log("modal");
    console.log("iamge", images, JSON.stringify(images));
    if (images === undefined) {
      console.log("nothing");
      return <></>;
    }

    let items = [];
    let id = 0;

    const initTitle = images[0].title;
    console.log("images", images);
    for (const img of images) {
      items.push(
        <Carousel.Item key={"Carousel.Item" + id}>
          <img
            className="d-block w-100"
            src={"imgs/" + img.url}
            alt={img.title}
          />
          {console.log("new img", id, img.url)}
        </Carousel.Item>
      );
      id++;
    }

    return (
      <Modal
        show={show}
        dialogClassName="modal-xl"
        onHide={() => {
          setImages();
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{images[modalTitleIndex].title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            fade
            variant="dark"
            onSelect={(e) => {
              setModalTitleIndex(e);
            }}
          >
            {items}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      {menu()}
      {modal(images)}
      <div className="App">
        <header className="App-header">
          <ComposableMap>
            <ZoomableGroup
              center={[-10, 50]}
              zoom={5}
              maxZoom={50}
              minZoom={5}
              onMoveEnd={({ a, zoom }) => {
                setZoomFactor(1 / zoom);
              }}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      fill={contryColor(geo.properties.name)}
                      stroke="#000000"
                      strokeWidth={0.2}
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none" },
                        pressed: { outline: "none" },
                      }}
                      geography={geo}
                      onClick={(event) => {
                        console.log("event", event);

                        console.log("geo>", JSON.stringify(geo));
                        setShow(true);
                      }}
                    />
                  ))
                }
              </Geographies>
              {Object.entries(IMAGES).map(
                ([
                  key,
                  { images, category, markerOffset, name, coordinates },
                ]) => (
                  <Marker
                    key={key}
                    coordinates={coordinates}
                    onClick={(e) => {
                      console.log("click marker");
                      setImages(images);
                      setShow(true);
                    }}
                  >
                    <circle
                      r={zoomFactor * 5}
                      fill={markerColor(category)}
                      stroke="#fff"
                      strokeWidth={zoomFactor * 0.1}
                    />
                    <text
                      fontSize={`${Math.min(1, zoomFactor * 13)}px`}
                      textAnchor="middle"
                      y={markerOffset}
                      style={{
                        fontFamily: "system-ui",
                        textShadow: "1px 1px 2px black",
                        fill: "white",
                      }}
                    >
                      {name}
                    </text>
                  </Marker>
                )
              )}
            </ZoomableGroup>
          </ComposableMap>
        </header>
      </div>
    </div>
  );
};

export default Main;
