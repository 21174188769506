import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import Main from "./Main";
import Login from "./login";

export default function App() {
    return (
	<>
	    <Router>
		<Routes>
		    <Route path="/login" element={<Login />} />
		    <Route path="/" element={<Main />} />
		</Routes>
	    </Router>
	</>
    );
}
