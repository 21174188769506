import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import './App.css';

const Login = () => {
  const [amplify, setAmplify] = useState(<div></div>);
  useEffect(() => {
    fetch("/public/cognito.json", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("stack:", json);
        Amplify.configure(json);

        setAmplify(
          <Authenticator>
            {({ signOut, user }) => {
              document.cookie =
                "idToken=" +
                user.signInUserSession.idToken.jwtToken +
                "; SameSite=Lax; path=/;";
              localStorage.setItem(
                "groups",
                user.signInUserSession.idToken.payload["cognito:groups"]
              );
              window.location = "/index.html";
            }}
          </Authenticator>
        );
      });
  }, []);
  return <>{amplify}</>;
};
export default Login;
